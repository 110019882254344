import React from 'react';
import Header from './Header';

const Layout = ({ children }) => {
  return (
    <div className="flex flex-col h-screen">
      <Header className="flex-none" />
      <div className="flex flex-grow overflow-hidden">
        <div className="flex-grow overflow-auto">
          {children}
        </div>
      </div>
    </div>
  );
};

export default Layout;