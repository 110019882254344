import React, { useState, useEffect } from 'react';
import axios from 'axios';

// TO DO: Replace 'http://localhost:8080' with the URL of your deployed backend
const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:8080';

const CategoryItem = ({ genre, category, file, shouldEvaluate, results, onResultsUpdate, setEvaluationProgress, evaluationStatus }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [collapsed, setCollapsed] = useState(false);
    const [hasEvaluated, setHasEvaluated] = useState(false);

    useEffect(() => {
        if (shouldEvaluate && file) {
            evaluateCategory();
        }
    }, [shouldEvaluate, file]);

    useEffect(() => {
        setHasEvaluated(results.length > 0 || evaluationStatus === 'no_errors');
        // Auto-collapse if no errors found
        if (results.length === 1 && results[0].message === "No errors found for this category.") {
            setCollapsed(true);
        } else {
            setCollapsed(false);
        }
    }, [results, evaluationStatus]);

    const evaluateCategory = async () => {
        if (isLoading) {
            console.log(`Evaluation for ${genre} - ${category} already in progress. Skipping.`);
            return;
        }

        setIsLoading(true);
        setError(null);
        setEvaluationProgress(true);
        setHasEvaluated(true);

        try {
            const formData = new FormData();
            formData.append('file', file);

            const response = await axios.post(
                `${API_URL}/evaluation/evaluate?genre=${encodeURIComponent(genre)}&category=${encodeURIComponent(category)}`,
                formData,
                { headers: { 'Content-Type': 'multipart/form-data' } }
            );

            const receivedResults = Array.isArray(response.data) ? response.data : [];

            if (receivedResults.length === 0) {
                onResultsUpdate([{ message: "No errors found for this category." }]);
            } else {
                onResultsUpdate(receivedResults);
            }
        } catch (err) {
            setError(`Error evaluating ${genre} - ${category}: ${err.response?.data?.detail || err.message}`);
            onResultsUpdate([]); // Clear results on error
        } finally {
            setIsLoading(false);
            setEvaluationProgress(false);
        }
    };

    const deleteResults = () => {
        onResultsUpdate([]);
    };

    const toggleCollapse = () => {
        setCollapsed(!collapsed);
    };

    const hasErrors = results.length > 0 && results[0].message !== "No errors found for this category.";
    const titleColorClass = hasEvaluated ? (hasErrors ? 'text-red-500' : 'text-green-500') : '';

    return (
        <div className="mb-2 font-montserrat">
            <div>
                <div className="flex justify-between items-center cursor-pointer" onClick={toggleCollapse}>
                    <h4 className={`font-semibold flex items-center ${titleColorClass}`}>
                        {collapsed ? (
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-4 h-4 mr-2">
                                <path fillRule="evenodd" d="M16.28 11.47a.75.75 0 0 1 0 1.06l-7.5 7.5a.75.75 0 0 1-1.06-1.06L14.69 12 7.72 5.03a.75.75 0 0 1 1.06-1.06l7.5 7.5Z" clipRule="evenodd" />
                            </svg>
                        ) : (
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-4 h-4 mr-2">
                                <path fillRule="evenodd" d="M12.53 16.28a.75.75 0 0 1-1.06 0l-7.5-7.5a.75.75 0 0 1 1.06-1.06L12 14.69l6.97-6.97a.75.75 0 1 1 1.06 1.06l-7.5 7.5Z" clipRule="evenodd" />
                            </svg>
                        )}
                        {category}
                    </h4>
                    <div className="flex items-center">
                        <button
                            onClick={(e) => {
                                e.stopPropagation();
                                deleteResults();
                            }}
                            className="border-none bg-transparent mr-2"
                            title="Delete results"
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="#000000" fill="none">
                                <path d="M19.5 5.5L18.8803 15.5251C18.7219 18.0864 18.6428 19.3671 18.0008 20.2879C17.6833 20.7431 17.2747 21.1273 16.8007 21.416C15.8421 22 14.559 22 11.9927 22C9.42312 22 8.1383 22 7.17905 21.4149C6.7048 21.1257 6.296 20.7408 5.97868 20.2848C5.33688 19.3626 5.25945 18.0801 5.10461 15.5152L4.5 5.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
                                <path d="M3 5.5H21M16.0557 5.5L15.3731 4.09173C14.9196 3.15626 14.6928 2.68852 14.3017 2.39681C14.215 2.3321 14.1231 2.27454 14.027 2.2247C13.5939 2 13.0741 2 12.0345 2C10.9688 2 10.436 2 9.99568 2.23412C9.8981 2.28601 9.80498 2.3459 9.71729 2.41317C9.32164 2.7167 9.10063 3.20155 8.65861 4.17126L8.05292 5.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
                                <path d="M9.5 16.5L9.5 10.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
                                <path d="M14.5 16.5L14.5 10.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
                            </svg>
                        </button>
                        <button
                            onClick={(e) => {
                                e.stopPropagation();
                                if (!isLoading) {
                                    evaluateCategory();
                                }
                            }}
                            disabled={isLoading}
                            className="border-none bg-transparent disabled:cursor-not-allowed"
                            title="Re-evaluate"
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99" />
                            </svg>
                        </button>
                    </div>
                </div>

                {!collapsed && (
                    <div className="mt-1">
                        {isLoading ? (
                            <p className="text-gray-500">Evaluating...</p>
                        ) : error ? (
                            <p className="text-red-500">{error}</p>
                        ) : results.length > 0 ? (
                            results[0].message === "No errors found for this category." ? (
                                <p className="text-gray-500">{results[0].message}</p>
                            ) : (
                                results.map((result, index) => (
                                    <div key={index} className="py-1 custom-line-height">
                                        <span className="font-bold text-gray-700">Criterion:</span> {result?.criterion || 'N/A'}<br />
                                        <span className="font-bold text-gray-700">Section Title:</span> {result?.section_title || 'N/A'}<br />
                                        <span className="font-bold text-gray-700">Complete Text:</span> {result?.complete_text || 'N/A'}<br />
                                        <span className="font-bold text-gray-700">Error Text:</span> {result?.error_text || 'N/A'}<br />
                                        <span className="font-bold text-gray-700">Comment:</span> {result?.comment || 'N/A'}<br />
                                        <span className="font-bold text-gray-700">Suggested Fix:</span> {result?.suggested_fix || 'N/A'}<br /><br />
                                        <div className='border-b border-dotted border-white'></div>
                                    </div>
                                ))
                            )
                        ) : (
                            <p className="text-gray-500">Not evaluated yet</p>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default CategoryItem;