import React from 'react';
import EvaluationPage from './components/EvaluationPage';
import Layout from './components/Layout'; // Correct import for Layout

function App() {
  return (
    <div className="App font-montserrat">
      <Layout>
        <EvaluationPage />
      </Layout>
    </div>
  );
}

export default App;
