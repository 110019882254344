import React, { useState, useEffect, useCallback } from 'react';
import CategoryItem from './CategoryItem';

const MainContent = ({ 
  uploadedFile, 
  categories, 
  evaluateAllTrigger,
  shouldEvaluateMissing, 
  setHasExistingResults, 
  onDeleteFile,
  evaluationResults,
  updateEvaluationResults,
  evaluationStatus,
  setEvaluationStatus
}) => {
  const [collapsedGenres, setCollapsedGenres] = useState({});
  const [triggerEvaluation, setTriggerEvaluation] = useState({});

  useEffect(() => {
    const hasResults = Object.values(evaluationResults).some(genre => 
      Object.values(genre).some(categoryResults => categoryResults && categoryResults.length > 0)
    );
    setHasExistingResults(hasResults);
  }, [evaluationResults, setHasExistingResults]);

  useEffect(() => {
    console.log("evaluateAllTrigger changed:", evaluateAllTrigger);
    if (evaluateAllTrigger > 0) {
      const newTrigger = {};
      Object.keys(categories).forEach(genre => {
        Object.keys(categories[genre]).forEach(category => {
          newTrigger[`${genre}-${category}`] = Date.now();
        });
      });
      setTriggerEvaluation(newTrigger);
    }
  }, [evaluateAllTrigger, categories]);

  useEffect(() => {
    if (shouldEvaluateMissing) {
      const newTrigger = {};
      Object.keys(categories).forEach(genre => {
        Object.keys(categories[genre]).forEach(category => {
          const key = `${genre}-${category}`;
          if (shouldEvaluateCategory(evaluationResults[genre]?.[category], evaluationStatus[key])) {
            newTrigger[key] = Date.now();
          }
        });
      });
      setTriggerEvaluation(prev => ({...prev, ...newTrigger}));
    }
  }, [shouldEvaluateMissing, categories, evaluationResults, evaluationStatus]);

  useEffect(() => {
    if (!uploadedFile) {
      setCollapsedGenres({});
      setTriggerEvaluation({});
      console.log('MainContent: Reset internal state due to file deletion');
    }
  }, [uploadedFile]);

  const toggleGenre = useCallback((genre) => {
    setCollapsedGenres(prev => ({ ...prev, [genre]: !prev[genre] }));
  }, []);

  const updateGenreResults = useCallback((genre, category, results) => {
    console.log(`Updating results for ${genre} - ${category}:`, results);
    updateEvaluationResults(genre, category, results);
    
    // Remove the trigger for this category after evaluation
    setTriggerEvaluation(prev => {
      const newTrigger = {...prev};
      delete newTrigger[`${genre}-${category}`];
      return newTrigger;
    });
  }, [updateEvaluationResults]);

  const setEvaluationProgress = useCallback((genre, category, inProgress) => {
    console.log(`Setting evaluation progress for ${genre} - ${category}:`, inProgress);
    setEvaluationStatus(prev => ({
      ...prev,
      [`${genre}-${category}`]: inProgress ? 'in_progress' : prev[`${genre}-${category}`]
    }));
  }, [setEvaluationStatus]);

  const shouldEvaluateCategory = useCallback((results, status) => {
    if (!results || results.length === 0) return true;
    if (status === 'error') return true;
    return false;
  }, []);

  const getGenreStatus = useCallback((genre) => {
    if (!evaluationResults[genre]) return 'Not evaluated';
    const categories = Object.values(evaluationResults[genre] || {});
    const hasErrors = categories.some(category =>
      Array.isArray(category) && category.some(result => !result.met && result.message !== "No errors found for this category.")
    );
    return hasErrors ? 'Failed' : 'Passed';
  }, [evaluationResults]);

  return (
    <div className="flex-grow overflow-hidden flex flex-col">
      <div className="p-4 border-b">
        {uploadedFile && (
          <div className="flex items-center justify-between">
            <p><strong>File: </strong>{uploadedFile.name}</p>
            <button
              onClick={onDeleteFile}
              className="border-none bg-transparent"
              title="Delete file"
            >
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
              </svg>
            </button>
          </div>
        )}
      </div>
      <div className="flex-grow overflow-y-auto p-4">
        {Object.entries(categories).map(([genre, genreCategories]) => (
          <div key={genre} >
            <div
              className="flex justify-between items-center p-0 cursor-pointer"
              onClick={() => toggleGenre(genre)}
            >
              <h3 className="text-lg font-bold flex items-center">
                {collapsedGenres[genre] ? (
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-4 h-4 mr-2">
                    <path fillRule="evenodd" d="M16.28 11.47a.75.75 0 0 1 0 1.06l-7.5 7.5a.75.75 0 0 1-1.06-1.06L14.69 12 7.72 5.03a.75.75 0 0 1 1.06-1.06l7.5 7.5Z" clipRule="evenodd" />
                  </svg>
                ) : (
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-4 h-4 mr-2">
                    <path fillRule="evenodd" d="M12.53 16.28a.75.75 0 0 1-1.06 0l-7.5-7.5a.75.75 0 0 1 1.06-1.06L12 14.69l6.97-6.97a.75.75 0 1 1 1.06 1.06l-7.5 7.5Z" clipRule="evenodd" />
                  </svg>
                )}
                <span>{genre}</span>
              </h3>
              <span className={`px-2 py-1 text-s font-semibold ${
                getGenreStatus(genre) === 'Passed' ? 'text-green-500' :
                getGenreStatus(genre) === 'Failed' ? 'text-red-500' :
                  'text-gray-800'
              }`}>
                {getGenreStatus(genre)}
              </span>
            </div>
            {!collapsedGenres[genre] && (
              <div className="bg-gray-100 p-2">
                {Object.keys(genreCategories).map((category) => (
                  <CategoryItem
                    key={`${genre}-${category}`}
                    genre={genre}
                    category={category}
                    file={uploadedFile}
                    shouldEvaluate={triggerEvaluation[`${genre}-${category}`] !== undefined}
                    results={evaluationResults[genre]?.[category] || []}
                    onResultsUpdate={(results) => updateGenreResults(genre, category, results)}
                    setEvaluationProgress={(inProgress) => setEvaluationProgress(genre, category, inProgress)}
                    evaluationStatus={evaluationStatus[`${genre}-${category}`]}
                  />
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default MainContent;