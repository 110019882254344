import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';

// TO DO: Replace 'http://localhost:8080' with the URL of your deployed backend
const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:8080';

const Sidebar = ({ onFileUploaded, onEvaluate, onEvaluateMissing, isFileUploaded, hasExistingResults, uploadedFileName, onDeleteAll }) => {
  const [uploadStatus, setUploadStatus] = useState('');

  const onDrop = useCallback(async (acceptedFiles) => {
    if (acceptedFiles.length === 0) {
      setUploadStatus('No files were accepted. Please try again.');
      return;
    }

    const file = acceptedFiles[0];
    if (file.type !== 'application/pdf' && !file.name.toLowerCase().endsWith('.pdf')) {
      setUploadStatus('Please upload a PDF file.');
      return;
    }

    setUploadStatus('Uploading file...');
    const formData = new FormData();
    formData.append('file', file);

    try {
      const uploadResponse = await axios.post(`${API_URL}/api/upload`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      if (uploadResponse.data.success) {
        setUploadStatus('File uploaded successfully!');
        onFileUploaded(file);
      } else {
        throw new Error(uploadResponse.data.message || 'Upload failed');
      }
    } catch (error) {
      console.error('Upload error:', error);
      setUploadStatus(`Error: ${error.response?.data?.detail || error.message || 'Upload failed'}`);
    }
  }, [onFileUploaded]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ 
    onDrop,
    accept: {'application/pdf': ['.pdf']},
    multiple: false
  });

  const handleEvaluateAll = () => {
    if (hasExistingResults) {
      const confirmEvaluate = window.confirm("There are existing results. Are you sure you want to re-evaluate all categories?");
      if (confirmEvaluate) {
        onEvaluate();
      }
    } else {
      onEvaluate();
    }
  };

  const buttonClass = `
    py-2 px-4 rounded w-full mb-2
    focus:outline-none focus:ring-2 focus:ring-opacity-50
    transition duration-200 ease-in-out
  `;

  return (
    <div className="w-64 bg-gray-100 border-r border-gray-300 flex flex-col h-full">
      <div className="flex-grow p-4 overflow-y-auto">
        <div {...getRootProps({ className: 'border-2 border-dashed border-blue-500 rounded p-4 text-center cursor-pointer mb-4' })}>
          <input {...getInputProps()} />
          {
            isDragActive ?
              <p className="text-blue-500">Drop the files here ...</p> :
              <p className="text-blue-500">Drag 'n' drop a PDF file here, or click to select one</p>
          }
        </div>
        <p className="mb-4">{uploadStatus}</p>
        {uploadedFileName && <p className="mb-4">Uploaded: {uploadedFileName}</p>}
        <button 
          onClick={handleEvaluateAll} 
          className="w-full mb-2 p-2 bg-blue-500 text-white hover:bg-blue-600 focus:ring-blue-500"
        >
          Evaluate All
        </button>
        <button 
          onClick={onEvaluateMissing} 
          disabled={!isFileUploaded}
          className={`w-full mb-2 p-2 ${isFileUploaded 
            ? 'bg-green-500 text-white hover:bg-green-600 focus:ring-green-500' 
            : 'bg-gray-300 text-gray-500 cursor-not-allowed'}`}
        >
          Evaluate Missing
        </button>
        <button 
          onClick={onDeleteAll} 
          className="w-full mb-2 p-2 bg-red-500 text-white hover:bg-red-600 focus:ring-red-500"
        >
          Delete All
        </button>
      </div>
    </div>
  );
};

export default Sidebar;